function ImageIcon(props: { onClick: () => void }) {
  const { onClick } = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="w-12 cursor-pointer"
      viewBox="0 -960 960 960"
      onClick={onClick}
    >
      <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm40-80h480L570-480 450-320l-90-120-120 160Zm-40 80v-560 560Z" />
    </svg>
  );
}

export default ImageIcon;
